/* eslint-disable */
import React from "react"
import { useState } from "react"
import Box from "@mui/material/Box"

import { Grid, Typography } from "@mui/material"
import { useLocation, useNavigate } from "react-router"
import { imgBaseURL } from "helper/Utility"
import { currencySign } from "helper/Constant"


export default function SaleDetails() {
    const navigate = useNavigate()
    const locationData = useLocation()
    const orderDetails = locationData?.state || null

    const product = orderDetails?.productId;
    const user = orderDetails?.userId;


    const handleBack = () => {
        navigate(-1);
    };
    return (
        <>
            <div className="section-tittle d-flex justify-content-between align-items-center">

                <div>
                    <button className="bck_btn" onClick={handleBack}>
                        <i class="fa-solid fa-arrow-left"></i>
                    </button>
                    <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
                        <span class="shape"></span>Booking Details
                    </typography>
                </div>
            </div>
            <Box class="main_outer" sx={{ width: "100%", typography: "body1" }}>
                <Box className="boxtbale p-4">
                    <Grid container spacing={1} justifyContent={"end"}>
                        <Grid item md={8} justifyContent={"center"}>
                            <Grid container spacing={1.5} className="form-inpitcustom">
                                <Grid item xs={12} className="mb-4">
                                    <h6 className="m-0" style={{ color: '#9d2aed' }}>Service Details</h6>
                                    <hr />
                                    <p><strong>Service ID:</strong> {product?._id}</p>
                                    <p><strong>Service Title:</strong> {product?.title}</p>
                                    <p><strong>Service Amount:</strong> {currencySign}{product?.price}</p>
                                    <p><strong>Paid Amount:</strong> {currencySign}{orderDetails?.total}</p>
                                    <p><strong>Description:</strong> {product?.description}</p>
                                    <p><strong>Gallery:</strong></p>
                                    {product?.gallery?.length > 0 ? (
                                        <div className="d-flex gap-2">
                                            {product.gallery.map((image, index) => (
                                                <div key={index}><img src={imgBaseURL() + image} alt={`Product Image ${index + 1}`} style={{ width: '100px', height: '100px', borderRadius: '10%' }} /></div>
                                            ))}
                                        </div>
                                    ) : (
                                        <p>No images available.</p>
                                    )}
                                </Grid>

                            </Grid>

                            <Grid item xs={12} className="mb-4">

                            </Grid>
                        </Grid>
                        <Grid item md={4} justifyContent={"center"}>
                            <Grid item xs={12} className="mb-4">
                                <h6 className="m-0" style={{ color: '#9d2aed' }}>User Details</h6>
                                <hr />
                                <p><strong>Name:</strong> {user?.fullName}</p>
                                <p><strong>Email:</strong> {user?.email}</p>
                            </Grid>


                            <Grid item xs={12} className="mb-4">
                                <h6 className="m-0" style={{ color: '#9d2aed' }}>Other Details</h6>
                                <hr />
                                <p><strong>Booking ID:</strong> {orderDetails._id}</p>
                                <p><strong>Date:</strong> {new Date(orderDetails.date).toLocaleString()}</p>
                                <p><strong>Slot:</strong> {orderDetails.slot}</p>
                                <p><strong>Booking Date:</strong> {new Date(orderDetails.createdAt).toLocaleString()}</p>
                                {/* <p><strong>Seller Name:</strong> {seller?.fullName}</p>
                                    <p><strong>Seller Email:</strong> {seller?.email}</p> */}
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
