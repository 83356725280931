/* eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button, Grid } from '@mui/material';
import LoginCard from 'ui-component/cards/LoginCard';
import { useNavigate, useParams } from 'react-router';
import { APICALL } from 'helper/api/api';
import { encryptLocalStorageData, RedirectFrontURI } from 'helper/Utility';

function Login() {
    const didMountRef = useRef(true);
    const navigate = useNavigate();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    const email = urlParams.get('email');
    const name = urlParams.get('name');
    const localToken = localStorage.getItem("AUTH_TOKEN")
    useEffect(() => {
        if (token || localToken) {
            if(token){
                localStorage.setItem("AUTH_TOKEN", token)
                navigate("/dashboard")
                // window.location.reload()
            }else{
                localStorage.setItem("AUTH_TOKEN", localToken)
                navigate("/dashboard")
                // window.location.reload()
            }
        } else {
            window.location.href = RedirectFrontURI()
        }
    }, [localToken, token]);


    return (
        <>
            {/* <div className="section-tittle">
                <typography variant="h1" className="tittle" sx={{ pt: 0 }}>
                    <span class="shape"></span>Login
                </typography>
            </div>

            <LoginCard className="outer-divv">
                    <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
                        <Grid item md={4} justifyContent={'center'} alignItems={'center'}>
                            <Grid container spacing={1}>
                                <Grid item sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <OutlinedInput placeholder="Email" onChange={(e)=>SetVendorEmail(e.target.value)} className="form-inpitcustomfiled" />
                                    </FormControl>
                                </Grid>
                                <Grid item sm={12} xs={12}>
                                    <FormControl fullWidth>
                                        <OutlinedInput placeholder="Password" type="password" onChange={(e)=>SetVendorPassword(e.target.value)} className="form-inpitcustomfiled" />
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className="editprofile mt-sm-3 mt-3" onClick={loginprocess}>Login</Button>
                            </Grid>
                        </Grid>
                    </Grid>
            </LoginCard> */}
        </>
    );
}
export default Login;
