/* eslint-disable */
import { lazy } from "react"

// project imports
import MainLayout from "layout/MainLayout"
import Loadable from "ui-component/Loadable"
import Login from "views/auth/login"
import SaleDetails from "views/utilities/SaleDetails"

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import("views/dashboard/Default")))
const IntroductionDefault = Loadable(lazy(() => import("views/dashboard/Introduction")))
// utilities routing
const Payout = Loadable(lazy(() => import("views/utilities/Payout")))
// const Membership = Loadable(lazy(() => import('views/utilities/Membership')));
const Chat = Loadable(lazy(() => import("views/utilities/Chat")))
const Sales = Loadable(lazy(() => import("views/utilities/Sales")))
const Currentgigs = Loadable(lazy(() => import("views/utilities/CurrentGigs")))
const AddGigs = Loadable(lazy(() => import("views/utilities/Addgigs")))
const EditProfile = Loadable(lazy(() => import("views/dashboard/EditProfile")))

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: "/",
    children: [
        {
            path: "/",
            element: <Login />
        },
        {
            path: "login/:id",
            element: <Login />
        },
        {
            path: "/",
            element: <MainLayout />,
            children: [
                {
                    path: "dashboard",
                    element: <DashboardDefault />
                },
                {
                    path: "introduction",
                    element: <IntroductionDefault />
                },
                {
                    path: "editprofile",
                    element: <EditProfile />
                },
                {
                    path: "utils",
                    children: [
                        {
                            path: "payout",
                            element: <Payout />
                        },
                        {
                            path: "chat",
                            element: <Chat />
                        },
                        {
                            path: "sales",
                            element: <Sales />
                        },
                        {
                            path: "sales-details",
                            element: <SaleDetails />
                        }
                    ]
                },
                {
                    path: "gigs",
                    children: [
                        {
                            path: "addgigs",
                            element: <AddGigs />
                        },
                        {
                            path: "viewgigs",
                            element: <Currentgigs />
                        }
                    ]
                }
            ]
        }
    ]
}

export default MainRoutes
