/* eslint-disable */
import { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Button,
    ButtonBase,
    CardActions,
    Chip,
    ClickAwayListener,
    Divider,
    Grid,
    ListItem,
    ListItemText,
    Paper,
    Popper,
    Stack,
    TextField,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
// import { ApiService } from 'services/apiservices';

// assets
import { IconBell } from '@tabler/icons';
import { APICALL } from 'helper/api/api';
// let apiServices = new ApiService();
// notification status options
const status = [
    {
        value: 'all',
        label: 'All Notification'
    },
    {
        value: 'new',
        label: 'New'
    },
    {
        value: 'unread',
        label: 'Unread'
    },
    {
        value: 'other',
        label: 'Other'
    }
];

// ==============================|| NOTIFICATION ||============================== //
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));
const NotificationSection = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const handleClose = (event) => {
        setOpen(false);
    };

    useEffect(() => {
        getNotifications()
    }, []);

    const handleOpen = () => {
        setOpen(true)
    }


    const [notificationList, setNotificationList] = useState([])
    const getNotifications = async () => {
        const res = await APICALL("user/userNotifications", "post", {})
        console.log("notificationList", res)
        if (res?.status) {
            setNotificationList(res?.data)
        } else {
            setNotificationList([])
        }
    }

    const handleClick = (type, data) => {
        // if (type === "sale") {
            navigate(`/utils/sales`)
             setOpen(false);
            readNotifications(data?._id)
        // }
    }

    const readNotifications = async (id) => {
        await APICALL("user/readNotification", "post", { notificationId: id })
    }
    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <ButtonBase sx={{ borderRadius: '12px' }} onClick={handleOpen}>
                    <IconBell stroke={1.6} size="1.8rem" style={{ color: 'white' }} />
                    {notificationList.length > 0 ?
                        <span className="MuiBadge-badge-177 MuiBadge-colorPrimary-177" style={{
                            top: "-11px", right: "-11px", width: "22px", height: "22px", display: "flex", zIndex: "1", position: "absolute",
                            flexWrap: "wrap",
                            fontSize: "0.75rem",
                            alignItems: "center",
                            fontFamily: "Roboto Helvetica Arial sans-serif",
                            alignContent: "center",
                            borderRadius: "50%",
                            flexDirection: "row",
                            justifyContent: "center", backgroundColor: "#e71de1"
                        }}>{notificationList.length}</span> : ""}
                </ButtonBase>

            </Box>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
                style={{
                    top: "70px", right: "15px", left: "auto", width: "320px"
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps} >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]} >
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography variant="subtitle1">All Notification</Typography>
                                                        {notificationList.length > 0 ?
                                                            <Chip
                                                                size="small"
                                                                label={notificationList.length}
                                                                sx={{
                                                                    color: theme.palette.background.default,
                                                                    bgcolor: theme.palette.warning.dark
                                                                }}
                                                            /> : ""}
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ListItemWrapper>
                                                {
                                                    notificationList?.length > 0 ?
                                                    notificationList?.map((item, i) => (
                                                        <ListItem onClick={() => handleClick(item?.type, item)}>
                                                            {/* <h6 className="msg-name text-capitalize">{item?.type}</h6> */}
                                                            <p className="msg-info">{item?.message}</p>
                                                        </ListItem>
                                                    ))
                                                    :
                                                    <p>No Notification Found</p>
                                                }
                                            </ListItemWrapper>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
